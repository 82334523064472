import React                       from 'react';
import TextBadgesList              from './Base/Badges/TextBadgesList';
import PropTypes                   from 'prop-types';
import Translations                from '../../../utils/Translations';
import Flux                        from '../../../flux/Flux';
import {formatCurrency, mergeDeep} from '../../../utils/CommonUtils';

const TextBadgeStyles     = Flux.Constants.Grid2.TextBadge.Styles;
const TextBadgeFontColors = Flux.Constants.Grid2.TextBadge.FontColors;

const VideoTileTextBadgesList = props => {
	const data   = props.data;
	const config = mergeDeep({}, VideoTileTextBadgesList.defaultProps.config, props.config);

	const textBadges = [];

	const guestInfo = data.guestInfo;
	const bought    = guestInfo && guestInfo.bought;
	const gifted    = guestInfo && guestInfo.gifted;
	const flatrate  = guestInfo && guestInfo.flatrate;

	const boughtOrGifted = bought || gifted;

	if (config.show.likes) {
		textBadges.push({
			parts: [{
				text:  config.show.likes,
				icon:  '-icon-like-full',
				color: TextBadgeFontColors.WHITE,
			}],
			style: TextBadgeStyles.DEFAULT,
		});
	}

	if (config.show.new && data.new) {
		textBadges.push({
			parts: [{
				text: Translations.get('BadgeNew'),
			}],
			style: TextBadgeStyles.HIGHLIGHT,
		});
	}
	if (config.show.fsk18 && typeof data.ageRating === 'number' && data.ageRating >= 18) {
		textBadges.push({
			parts: [{
				text: Translations.get('Over18'),
			}],
			style: TextBadgeStyles.HIGHLIGHT,
		});
	}
	if (config.show.bought && boughtOrGifted && !flatrate) {
		const part = {
			text: Translations.get(gifted ? 'Gift' : 'BoughtWithoutIcon'),
		};

		if (!gifted) {
			part.icon = '-icon-success-rounded-full h-mr-2';
		}

		textBadges.push({
			parts: [part],
			style: TextBadgeStyles.HIGHLIGHT,
		});
	}
	if (config.show.price && (data.vip || (!config.hidePriceIfBought || !boughtOrGifted)) && !(flatrate && data.isVip30Offer)) {
		if (data.vip) {
			textBadges.push({
				parts: [{
					text:  Translations.get('VideoGridVip'),
					icon:  '-icon-person-vip',
					color: TextBadgeFontColors.BLACK,
				}],
				style: TextBadgeStyles.SHAPE,
			});
		} else if (data.free || flatrate) {
			textBadges.push({
				parts: [{
					text: Translations.get('FreeClip'),
				}],
				style: TextBadgeStyles.DEFAULT,
			});
		} else if (data.price && typeof data.price.value === 'number') {
			const hasDiscount = data.basePrice && typeof data.basePrice.value === 'number' && data.basePrice.value !== data.price.value;

			const parts = [{
				text:  formatCurrency(data.price.value, null, data.price.currency),
				color: hasDiscount ? TextBadgeFontColors.HIGHLIGHT : TextBadgeFontColors.WHITE,
			}];

			if (hasDiscount) {
				parts.unshift({
					text:    formatCurrency(data.basePrice.value, null, data.basePrice.currency),
					color:   TextBadgeFontColors.WHITE,
					crossed: true,
				});
			}

			textBadges.push({
				parts,
				style: TextBadgeStyles.DEFAULT,
			});
		}
	}
	if (config.show.contest && data.contestInfo) {
		textBadges.push({
			parts: [{
				text: Translations.get('BadgeContest'),
			}],
			style: TextBadgeStyles.DEFAULT,
		});
	}
	if (config.show.camRecording && data.camRecording) {
		textBadges.push({
			parts: [{
				text: Translations.get('CamRecording'),
			}],
			style: TextBadgeStyles.DEFAULT,
		});
	}

	if (config.show.classic && data.classic) {
		textBadges.push({
			parts: [{
				text: 'CLASSIC',
			}],
			style: TextBadgeStyles.DEFAULT,
		});
	}

	return <TextBadgesList textBadges={textBadges} alignRight={config.alignRight}/>;
};

export const VideoTileTextBadgesListConfigType = PropTypes.shape({
	show:              PropTypes.shape({
		new:     PropTypes.bool,
		fsk18:   PropTypes.bool,
		bought:  PropTypes.bool,
		contest: PropTypes.bool,
		price:   PropTypes.bool,
		likes:   PropTypes.number,
		rating:  PropTypes.bool,
	}),
	hidePriceIfBought: PropTypes.bool,
	alignRight:        PropTypes.bool,
});

VideoTileTextBadgesList.propTypes = {
	data:   PropTypes.object.isRequired, // VXQL Video type
	config: VideoTileTextBadgesListConfigType,
};

VideoTileTextBadgesList.defaultProps = {
	config: {
		show:              {
			new:          true,
			fsk18:        true,
			bought:       true,
			contest:      true,
			price:        false,
			camRecording: false,
			likes:        0,
			classic:      true,
			rating:       false,
		},
		hidePriceIfBought: true,
		alignRight:        false,
	},
};

export default VideoTileTextBadgesList;