import React                                                        from "react";
import PropTypes                                                    from "prop-types";
import VideoTileTextBadgesList, {VideoTileTextBadgesListConfigType} from "./VideoTileTextBadgesList";
import Badges                                                       from "./Base/Badges/Badges";
import BadgesColumn                                                 from "./Base/Badges/BadgesColumn";
import ContestPlaceBadge                                            from "./Base/Badges/ContestPlaceBadge";
import RatingBadge                                                  from "./Base/Badges/RatingBadge";
import {TileDisplayConfigType}                                      from "../Grid2";

const VideoTileBadges = props => {
	const data          = props.data;
	const displayConfig = props.displayConfig;
	const config        = props.config;

	const showContestPlace = data.contestInfo && typeof data.contestInfo.place === 'number' && data.contestInfo.place >= 1 && data.contestInfo.place <= 3;
	const showRating       = config.textBadges.show.rating;

	return (
		<Badges displayConfig={displayConfig}>
			{showRating &&
			<BadgesColumn noPadding={true}>
				<RatingBadge rating={data.rating.likes} index={props.index}/>
			</BadgesColumn>}
			<BadgesColumn grow={true} shrink={true}>
				<VideoTileTextBadgesList data={data} config={config.textBadges} />
			</BadgesColumn>
			{showContestPlace &&
			<BadgesColumn>
				<ContestPlaceBadge place={data.contestInfo.place} />
			</BadgesColumn>}
		</Badges>
	);
};

export const VideoTileBadgesConfigType = PropTypes.shape({
	textBadges: VideoTileTextBadgesListConfigType,
	rating:     PropTypes.bool,
});

VideoTileBadges.propTypes = {
	data:          PropTypes.object.isRequired, // VXQL Video type
	displayConfig: TileDisplayConfigType.isRequired,
	config:        VideoTileBadgesConfigType,
	index:         PropTypes.number,
};

VideoTileBadges.defaultProps = {
	textBadges: VideoTileTextBadgesList.defaultProps.config,
};

export default VideoTileBadges;
