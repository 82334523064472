import React          from "react";
import PropTypes      from "prop-types";
import TextBadgesList from './TextBadgesList';
import Flux           from '../../../../../flux/Flux';

const TextBadgeStyles     = Flux.Constants.Grid2.TextBadge.Styles;
const TextBadgeFontColors = Flux.Constants.Grid2.TextBadge.FontColors;

const RatingBadge = props => {
	if (props.index < 3) {
		return (
			<div className={"grid-2__tile__badges-rating"}>
			<div className={"grid-2__tile__badges-rating__rank icon-actor grid-2__tile__badges-rating__rank--" + (props.index + 1)}></div>
			<div className={"grid-2__tile__badges-rating__likes"}><i className={"icon -icon-like-full"}></i>{props.rating}</div>
		</div>
		);
	}

	const textBadges = [{
			parts: [{
				text:  props.rating,
				icon:  '-icon-like-full',
				color: TextBadgeFontColors.WHITE,
			}],
			style: TextBadgeStyles.DEFAULT,
		},
	];
	
	return <TextBadgesList textBadges={textBadges} alignRight={false} />;
};

RatingBadge.propTypes = {
	rating: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
};

export default RatingBadge;