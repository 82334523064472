import React     from "react";
import PropTypes from "prop-types";

const ContestPlaceBadge = props => (
	<i className={`grid-2__tile__badges__contest-place-badge icon-actor -icon-award-user-ranking-${props.place}`}></i>
);

ContestPlaceBadge.propTypes = {
	place: PropTypes.number.isRequired,
};

export default ContestPlaceBadge;